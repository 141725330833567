@import '../theme-bootstrap';

.related-pages-wrapper {
  background: $color--white;
}

.elc-related-pages {
  &--wrapper {
    margin: 0 auto;
    max-width: 1440px;
    padding: 20px;
    @include breakpoint($bp--medium-up) {
      display: flex;
    }
  }
  &--label {
    @include sub-title--bold();
    text-transform: uppercase;
    white-space: nowrap;
    &:after {
      content: ':';
    }
  }
  &--link {
    @include sub-title();
    overflow: hidden;
    border: none;
    margin-top: 8px;
    @include breakpoint($bp--medium-up) {
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-#{$ldirection}: 20px;
      margin-top: 0;
    }
    &-wrapper {
      display: inline-grid;
      grid-template-columns: repeat(1, auto);
      padding-top: 8px;
      @include breakpoint($bp--medium-up) {
        padding-top: 0;
        grid-template-columns: repeat(3, auto);
      }
    }
  }
}
